import { produce } from 'immer';
export const reducer = (state, action) => produce(state, draft => {
  switch (action.type) {
    case 'set-consent':
      {
        draft.isConsentGiven = action.detail.type === 'explicit';
        draft.consent = {
          ...action.detail
        };
        if (action.detail.type === 'implicit') {
          window.UC_UI?.getServicesBaseInfo().forEach(baseInfo => {
            try {
              if (draft.consent) {
                draft.consent[baseInfo.name] = baseInfo.isEssential;
              }
            } catch (_) {
              // If the assignment above fails for some reason, we just ignore it.
            }
          });
        }
        return draft;
      }
    case 'set-requirement':
      {
        draft.isRequired = action.isRequired;
        return draft;
      }
    case 'set-analytics-id':
      {
        draft.analyticsId = action.analyticsId;
        return draft;
      }
    default:
      throw new Error('Unknown action type');
  }
});