import { capitalizeFirstLetter } from '../../../play-core/utils/string-helper';
export const tagCommanderMiddleware = eventTracker => _store => _next => action => {
  switch (action.type) {
    case 'click-event':
      {
        eventTracker.track({
          event_type: 'ClickEvent',
          event_name: action.name,
          event_value_1: 'value1' in action ? action.value1 : undefined,
          event_value_2: 'value2' in action ? action.value2 : undefined,
          event_value_3: 'value3' in action ? action.value3 : undefined,
          event_value_4: 'value4' in action ? action.value4 : undefined
        });
        break;
      }
    case 'perform-search':
      {
        eventTracker.track({
          event_type: 'SearchFlyout',
          event_name: 'OpenSearchPage',
          event_value_1: action.referrerPage,
          event_value_2: action.searchTerm.toLowerCase()
        });
        break;
      }
    case 'change-search-topic-filter':
      {
        eventTracker.track({
          event_type: 'SearchFlyout',
          event_name: 'SetTopicFilter',
          event_value_1: 'searchPage',
          event_value_2: action.searchTerm.toLowerCase()
        });
        break;
      }
    case 'change-search-show-filter':
      {
        eventTracker.track({
          event_type: 'SearchFlyout',
          event_name: 'SetShowFilter',
          event_value_1: 'searchPage',
          event_value_2: action.searchTerm.toLowerCase()
        });
        break;
      }
    case 'change-search-property-filter':
      {
        eventTracker.track({
          event_type: 'SearchFlyout',
          event_name: 'SetPropertyFilter',
          event_value_1: 'searchPage',
          event_value_2: action.searchTerm.toLowerCase(),
          event_value_3: action.property
        });
        break;
      }
    case 'change-search-date-filter':
      {
        eventTracker.track({
          event_type: 'SearchFlyout',
          event_name: 'SetDateFilter',
          event_value_1: 'searchPage',
          event_value_2: action.searchTerm.toLowerCase()
        });
        break;
      }
    case 'open-search-result':
      {
        eventTracker.track({
          event_type: 'SearchFlyout',
          event_name: 'OpenMedia',
          event_value_1: action.referrerPage,
          event_value_2: action.searchTerm.toLowerCase(),
          event_value_4: action.resultIndex
        });
        break;
      }
    case 'ab-testing-config':
      {
        eventTracker.track({
          event_type: 'HiddenEvent',
          event_name: `ABTest-${capitalizeFirstLetter(action.testName)}`,
          event_value_1: `Group-${action.group}`,
          event_value_2: action.scenario
        });
        break;
      }
    default:
  }
};