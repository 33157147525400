import { watchLaterStorage } from '../../storage/watch-later-storage';
export const watchLaterStorageMiddleware = store => next => action => {
  next(action);
  const state = store.getState();
  switch (action.type) {
    case 'toggle-watch-later':
    case 'set-watch-later-urns':
    case 'set-watch-later-videos':
    case 'clear-watch-later':
    case 'undo-clear-watch-later':
      {
        watchLaterStorage.set(state.watchLaterState.videoUrns);
        break;
      }
    default:
  }
};