import { useMemo } from 'react';
import globalAxios from 'axios';
import Cookies from 'js-cookie';
import { toQueryString } from '../../../play-core/utils/request-url-helper';
import { idToUrn, urnToId } from '../../../play-core/utils/urn-helper';
import { useDataDispatch } from '../providers/DataProvider/DataContext';
const peachInfoCookie = 'peach_infos';
const identityCookie = 'identity.provider.sid';
export const createRtsAxios = onLogout => {
  const axiosInstance = globalAxios.create({
    baseURL: 'https://profil.rts.ch/api',
    withCredentials: true
  });
  axiosInstance.interceptors.response.use(response => response, error => {
    if (error?.response?.status === 401) {
      onLogout();
      logoutRtsUser();
    }
  });
  return axiosInstance;
};
export const useRtsAxios = () => {
  const dispatch = useDataDispatch();
  return useMemo(() => createRtsAxios(() => dispatch({
    type: 'set-logged-in',
    isLoggedIn: false
  })), [dispatch]);
};
export const isRtsUserLoggedIn = () => !!Cookies.get(peachInfoCookie);
export const logoutRtsUser = () => {
  Cookies.remove(peachInfoCookie, {
    domain: '.rts.ch'
  });
  Cookies.remove(identityCookie, {
    domain: '.rts.ch'
  });
};
export const loadRtsVideoHistory = async axios => {
  const {
    data: {
      data: history
    }
  } = await axios.get(`/history/v2?cb=${new Date().getTime()}`);
  return history.filter(entry => !entry.deleted && entry.item_id.includes(':video:')).map(entry => ({
    id: urnToId(entry.item_id),
    progressInSeconds: entry.last_playback_position,
    timestamp: entry.date
  }));
};
export const updateRtsHistory = async (progress, axios) => {
  const entry = {
    item_id: idToUrn(progress.id, 'rts', 'video'),
    last_playback_position: progress.progressInSeconds,
    device_id: 'srg-player',
    deleted: false,
    date: progress.timestamp
  };
  await axios.post('/history/v2', entry);
};
export const loadRtsWatchLater = async axios => {
  const {
    data
  } = await axios.get(`/playlist/v3/watch_later?cb=${new Date().getTime()}`);
  const urns = data?.bookmarks?.map(bookmark => bookmark.itemId) ?? [];
  return urns;
};
export const deleteRtsWatchLater = async (urns, axios) => {
  const {
    data
  } = await axios.delete(`/playlist/v3/watch_later/bookmarks${toQueryString({
    mediaIds: urns
  })}`);
  return data;
};
export const addRtsWatchLater = async (urns, axios) => {
  const {
    data
  } = await axios.put('/playlist/v3/watch_later/bookmarks', urns.map(urn => ({
    itemId: urn
  })));
  return data;
};
export const loadRtsProfile = async axios => {
  const {
    data
  } = await axios.get('/profile/v3');
  return data;
};