import { playLocalStorage } from './play-local-storage';
const storageKey = 'playSRG-personalizationSettings';
const defaultValue = {
  settings: {
    showHeaderMessage: true
  }
};
const mapToPersonalization = personalizationStorage => {
  const {
    settings: {
      showHeaderMessage,
      personalizationInactive
    }
  } = personalizationStorage;
  return {
    showHeaderMessage,
    isActive: !personalizationInactive
  };
};
const get = () => {
  const {
    getItem
  } = playLocalStorage(storageKey, defaultValue);
  return mapToPersonalization(getItem());
};
const set = personalization => {
  const {
    setItem
  } = playLocalStorage(storageKey, defaultValue);
  const settings = {
    showHeaderMessage: personalization.showHeaderMessage,
    personalizationInactive: !personalization.isActive
  };
  return mapToPersonalization(setItem({
    settings
  }));
};
export const personalizationSettings = {
  get,
  set
};
export const isPersonalizationActive = () => {
  const settings = get();
  return settings.isActive;
};