export const snackbarMiddleware = (snackbarDispatch, i18n, myVideosSupported, paths) => store => next => action => {
  const state = store.getState();
  const {
    dispatch
  } = store;
  const myVideosLink = myVideosSupported ? {
    text: i18n.t('myVideos.title'),
    onClick: paths.myVideoPath()
  } : undefined;
  const showSnackbar = (message, snackbarAction) => {
    snackbarDispatch({
      type: 'add-snackbar',
      snackbarEntry: {
        message,
        action: snackbarAction
      }
    });
  };
  switch (action.type) {
    case 'toggle-topic-favorite':
      {
        const topic = state.topicState.userTopics.find(userTopic => userTopic.id === action.topicId);
        if (topic && !topic.isFavorite && action.showSnackbar) {
          showSnackbar(`"${topic.title}" ${i18n.t('global.markedAsFavorite')}`, myVideosLink);
        } else if (topic && topic.isFavorite && action.showSnackbar) {
          const undoAction = action.showFavoriteUndo ? {
            text: i18n.t('global.undo'),
            onClick: () => {
              dispatch({
                type: 'set-topic-favorites',
                topicFavorites: state.topicState.topicFavorites
              });
              showSnackbar(i18n.t('global.actionUndone'));
            }
          } : undefined;
          showSnackbar(`"${topic.title}" ${i18n.t('global.removedFromFavorites')}`, undoAction);
        }
        break;
      }
    case 'toggle-watch-later':
      {
        const isAdded = !state.watchLaterState.videoUrns.includes(action.urn);
        if (isAdded) {
          showSnackbar(i18n.t('global.addedToWatchLater'), myVideosLink);
        } else {
          const undoAction = action.showUndo ? {
            text: i18n.t('global.undo'),
            onClick: () => {
              dispatch({
                type: 'set-watch-later-videos',
                videos: state.watchLaterState.videos
              });
              showSnackbar(i18n.t('global.actionUndone'));
            }
          } : undefined;
          showSnackbar(i18n.t('global.removedFromWatchLater'), undoAction);
        }
        break;
      }
    case 'toggle-show-favorite':
      {
        if (action.showSnackbar) {
          const isAdded = !state.showState.favoriteShowIds.includes(action.showId);
          if (isAdded) {
            showSnackbar(`"${action.showTitle}" ${i18n.t('global.markedAsFavorite')}`, myVideosLink);
          } else {
            const undoAction = action.showUndo ? {
              text: i18n.t('global.undo'),
              onClick: () => dispatch({
                type: 'undo-clear-show-favorites',
                showState: state.showState
              })
            } : undefined;
            showSnackbar(`"${action.showTitle}" ${i18n.t('global.removedFromFavorites')}`, undoAction);
          }
        }
        break;
      }
    case 'undo-clear-show-favorites':
      {
        showSnackbar(i18n.t('global.actionUndone'));
        break;
      }
    case 'clear-show-favorites':
      {
        showSnackbar(i18n.t('global.wasDeleted', {
          title: i18n.t('home.favoriteShows')
        }), {
          text: i18n.t('global.undo'),
          onClick: () => dispatch({
            type: 'undo-clear-show-favorites',
            showState: state.showState
          })
        });
        break;
      }
    case 'set-personalization':
      {
        if (action.showNotification) {
          if (action.enable) {
            showSnackbar(i18n.t('personalization.enabled'));
          } else {
            showSnackbar(i18n.t('personalization.disabled'));
          }
        }
        break;
      }
    case 'undo-clear-watch-later':
      {
        showSnackbar(i18n.t('global.actionUndone'));
        break;
      }
    case 'clear-watch-later':
      {
        showSnackbar(i18n.t('global.wasDeleted', {
          title: i18n.t('global.watchLater')
        }), {
          text: i18n.t('global.undo'),
          onClick: () => dispatch({
            type: 'undo-clear-watch-later',
            watchLater: state.watchLaterState
          })
        });
        break;
      }
    default:
  }
  next(action);
};