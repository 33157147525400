import { channelHistoryStorage } from '../../storage/channel-history-storage';
import { favoriteGuideStorage } from '../../storage/favorite-guide-storage';
import { favoriteShowsStorage } from '../../storage/favorite-shows-storage';
import { favoriteTopicsStorage } from '../../storage/favorite-topics-storage';
import { personalizationSettings } from '../../storage/personalization-settings';
import { subtitleStorage } from '../../storage/subtitle-storage';
import { userIdStorage } from '../../storage/user-id-storage';
import { videoHistoryStorage } from '../../storage/video-history-storage';
import { watchLaterStorage } from '../../storage/watch-later-storage';
export const personalizationStorageMiddleware = store => next => action => {
  next(action);
  const state = store.getState();
  switch (action.type) {
    case 'load-user-id-success':
      {
        userIdStorage.set(action.userId);
        break;
      }
    case 'toggle-show-subtitle':
      {
        subtitleStorage.set(state.subtitleState.showSubtitle);
        break;
      }
    case 'set-personalization':
      {
        const {
          showHeaderMessage
        } = personalizationSettings.get();
        const newPersonalizationSettings = personalizationSettings.set({
          showHeaderMessage,
          isActive: state.personalization.isActive
        });
        if (!newPersonalizationSettings.isActive) {
          resetLocalStorage();
        }
        break;
      }
    case 'set-favorite-guide':
      {
        favoriteGuideStorage.set(action.favoriteGuide);
        break;
      }
    case 'add-channel-history':
      {
        channelHistoryStorage.set(state.channelHistory);
        break;
      }
    default:
  }
};
const resetLocalStorage = () => {
  favoriteTopicsStorage.reset();
  watchLaterStorage.reset();
  userIdStorage.reset();
  favoriteShowsStorage.reset();
  videoHistoryStorage.reset();
  subtitleStorage.reset();
  favoriteGuideStorage.reset();
  channelHistoryStorage.reset();
};