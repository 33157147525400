import { isValid, parse } from 'date-fns';
import { isSameDayOrBefore } from '../../../../play-core/utils/date-helper';
import { isoDate } from '../../utils/date-formatter';
export const parseToIsoDate = dateString => {
  if (dateString) {
    const allowedFormats = ['d.M.yy', 'd.M.yyyy', 'ddMMyyyy', 'd.M.', 'd.M', 'd.', 'd'];
    const parsedDates = allowedFormats.map(format => parse(dateString, format, new Date()));
    const validDate = parsedDates.find(date => isValid(date));
    if (validDate) {
      return isoDate(validDate);
    }
  }
};
export const parseAndValidateRange = (stringValue, range) => {
  if (stringValue === '') {
    return {
      date: undefined,
      validity: 'Valid'
    };
  }
  const date = parseToIsoDate(stringValue);
  if (date) {
    const validity = validateRange(date, range);
    return {
      date,
      validity
    };
  }
  return {
    date,
    validity: 'Invalid'
  };
};
export const validateRange = (date, range) => {
  if (date) {
    if (range.to && !isSameDayOrBefore(date, range.to)) {
      return 'AfterMax';
    }
    if (range.from && !isSameDayOrBefore(range.from, date)) {
      return 'BeforeMin';
    }
    return 'Valid';
  }
  return date ? 'Valid' : 'Invalid';
};
export const checkRangeValidity = (range, allowOpenRange) => {
  if (range.from && range.to) {
    if (isSameDayOrBefore(range.from, range.to)) {
      return 'Valid';
    }
    return 'InvalidRange';
  }
  if (range.from || range.to) {
    if (allowOpenRange) {
      return 'Valid';
    }
    return 'IncompleteRange';
  }
  return 'Valid';
};
export const areRangesEqual = (left, right) => {
  if (left && right) {
    return left.from === right.from && left.to === right.to;
  }
  return !(left || right);
};
export const isInRange = (date, range) => {
  if (range.from && range.to) {
    return validateRange(date, range) === 'Valid';
  }
  return false;
};