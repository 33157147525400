const audioUrl = 'https://www.srf.ch/audio';
export const defaultHeaderConfig = {
  audioLink: {
    link: audioUrl,
    title: 'Zum Audiobereich'
  },
  menuEntries: [{
    type: 'Link',
    title: 'Zum Audiobereich wechseln',
    url: audioUrl,
    icon: 'SwitchSection'
  }, {
    type: 'Divider'
  }, {
    type: 'Link',
    title: 'Startseite SRF',
    url: 'https://www.srf.ch',
    showDefaultIcon: true
  }, {
    type: 'Link',
    title: 'Radio-Programm',
    url: 'https://www.srf.ch/radio-programm',
    showDefaultIcon: true
  }, {
    type: 'Link',
    title: 'SRF Timelines',
    url: 'https://timelines.srf.ch/',
    showDefaultIcon: true
  }]
};