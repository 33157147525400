export const smallRange = {
  from: 0,
  to: 719
};
export const mediumRange = {
  from: 720,
  to: 1023
};
export const largeRange = {
  from: 1024,
  to: 1279
};
export const xLargeRange = {
  from: 1280
};
export const mediaQueries = {
  small: `only screen and (min-width:${smallRange.from}px) and (max-width: ${smallRange.to}px)`,
  medium: `only screen and (min-width:${mediumRange.from}px) and (max-width: ${mediumRange.to}px)`,
  large: `only screen and (min-width:${largeRange.from}px) and (max-width: ${largeRange.to}px)`,
  mediumUp: `only screen and (min-width:${mediumRange.from}px)`,
  mediumDown: `only screen and (max-width:${mediumRange.to}px)`,
  largeUp: `only screen and (min-width:${largeRange.from}px)`,
  xLargeUp: `only screen and (min-width:${xLargeRange.from}px)`
};
export const Viewports = {
  for: viewport => `@media ${mediaQueries[viewport]}`
};
const orientationMediaQueries = {
  portrait: '(orientation: portrait)',
  landscape: '(orientation: landscape)'
};
export const Orientations = {
  for: orientation => `@media ${orientationMediaQueries[orientation]}`
};