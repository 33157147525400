import { produce } from 'immer';
import { defaultPlayConfig } from './PlayConfig';
import { defaultFooterConfig } from '../components/Footer/Footer.globals';
import { defaultHeaderConfig } from '../components/Header/Header.config';
import { homeSectionTopSpacing } from '../pages/Home/HomePage.styles';
import { defaultSearchConfig } from '../pages/Search/Search.config';
import { defaultTvLiveConfig } from '../pages/TvLive/TvLive.config';
import { defaultVideoDetailConfig } from '../pages/VideoDetail/VideoDetailPage.config';
import { defaultUiConfig } from '../providers/UiConfigProvider/UiConfigContext';
export const createUiConfigFromDefault = defaultConfig => {
  const baseConfig = {
    default: defaultConfig
  };
  return produce(baseConfig, draftBase => {
    draftBase.home = produce(draftBase.default, draft => {
      draft.sections.topSpacing = homeSectionTopSpacing;
      return draft;
    });
    draftBase.show = produce(draftBase.default, draft => {
      draft.mediaTeaser.displayShowTitle = false;
      return draft;
    });
    return draftBase;
  });
};
export const defaultVideoConfig = {
  ...defaultPlayConfig,
  uiConfig: createUiConfigFromDefault(defaultUiConfig),
  usePreview: false,
  personalizationSupported: true,
  loginSupported: false,
  favoritesSupported: true,
  showsSupported: true,
  myVideosSupported: true,
  showBroadcastSupported: false,
  displayShowTitles: false,
  header: defaultHeaderConfig,
  footer: defaultFooterConfig,
  videoDetail: defaultVideoDetailConfig,
  search: defaultSearchConfig,
  tvLive: defaultTvLiveConfig,
  supportShowPosterImage: false,
  supportSearchOrder: true,
  supportRandomEditorialPicks: true,
  supportSubtitleOnTeaser: true,
  supportActiveShowFilter: false,
  surveys: {},
  preferredShowDescription: 'lead',
  supportLegacyBrowserHomepageLink: true,
  supportStandalonePlayer: true,
  abTesting: {
    recommender: {
      groupA: 0
    }
  },
  udpTrackingBaseUrl: 'https://prd.api.datahub-srgssr.ch/tracking',
  supportUdpConsentEventTracking: false,
  initialRequestUrl: ''
};