import { addRtsWatchLater, createRtsAxios, deleteRtsWatchLater } from '../../utils/rts-login-helper';
export const rtsWatchLaterStorageMiddleware = store => next => action => {
  const stateBeforeAction = store.getState();
  const {
    dispatch
  } = store;
  const rtsAxios = createRtsAxios(() => dispatch({
    type: 'set-logged-in',
    isLoggedIn: false
  }));
  next(action);
  const state = store.getState();
  switch (action.type) {
    case 'toggle-watch-later':
      {
        const isAdded = state.watchLaterState.videoUrns.includes(action.urn);
        if (isAdded) {
          addRtsWatchLater([action.urn], rtsAxios);
        } else {
          deleteRtsWatchLater([action.urn], rtsAxios);
        }
        break;
      }
    case 'clear-watch-later':
      {
        deleteRtsWatchLater(stateBeforeAction.watchLaterState.videoUrns, rtsAxios);
        break;
      }
    case 'undo-clear-watch-later':
      {
        addRtsWatchLater(state.watchLaterState.videoUrns, rtsAxios);
        break;
      }
    default:
  }
};