import { toQueryString } from '../../../../play-core/utils/request-url-helper';
import { toSeoString } from '../../../../play-core/utils/to-seo-string';
const pageSection = 'tv';
export const defaultLiveTVRoute = i18n => ({
  pageId: 'liveTV',
  pageType: 'live',
  pageSection,
  path: `/${pageSection}/${i18n.t('routes.live')}/:channelTitle?`,
  remixRoute: 'TvLivePage.remix-route.tsx'
});
export const liveSecondLevelNaviLinks = (i18n, livestreams, baseUrl) => livestreams.map(livestream => ({
  title: livestream.title,
  // TODO philipp: Maybe move this to route factory to centralize the baseUrl logic
  path: baseUrl + livePagePath(i18n)(livestream),
  isActive: (_, urlParams) => urlParams.get('tvLiveId') === livestream.id
}));
export const livePagePath = i18n => livestream => `/${pageSection}/${i18n.t('routes.live')}/${toSeoString(livestream.title)}${toQueryString({
  tvLiveId: livestream.id
})}`;