import { parseToBusinessUnit } from '../../../play-core/models/BusinessUnit';
import { isEnvironment } from '../../../play-core/models/Environment';
export const getBusinessUnitFromLocation = () => {
  const [businessUnit] = getPathnameSegments();
  return parseToBusinessUnit(businessUnit) || 'srf';
};
export const getEnvironmentFromLocation = () => {
  const [, environment] = getPathnameSegments();
  return isEnvironment(environment) ? environment : 'production';
};
const getPathnameSegments = () => window.location.pathname.split('/').filter(segment => !!segment);