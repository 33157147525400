const pageSection = 'tv';
export const liveEventsPath = i18n => `/${pageSection}/${i18n.t('routes.liveEvents')}`;
export const liveEventsPageRoute = i18n => ({
  pageId: 'liveEvents',
  pageType: 'live',
  pageSection,
  path: liveEventsPath(i18n),
  remixRoute: 'LiveEventsPage.remix-route.tsx'
});
export const liveEventsSecondLevelNaviLink = (i18n, baseUrl) => ({
  title: i18n.t('global.liveEvents'),
  // TODO philipp: Maybe move this to route factory to centralize the baseUrl logic
  path: baseUrl + liveEventsPath(i18n),
  isActive: route => route.path === liveEventsPath(i18n)
});