import { css } from 'styled-components';
const fontWeightMapper = {
  Regular: 400,
  Light: 300,
  Medium: 500,
  Bold: 700
};
export const Fonts = {
  regular: (fontSize, elementType) => Font(fontWeightMapper.Regular, fontSize, elementType),
  light: (fontSize, elementType) => Font(fontWeightMapper.Light, fontSize, elementType),
  medium: (fontSize, elementType) => Font(fontWeightMapper.Medium, fontSize, elementType),
  bold: (fontSize, elementType) => Font(fontWeightMapper.Bold, fontSize, elementType),
  byType: (fontType, fontSize, elementType) => Font(fontWeightMapper[fontType], fontSize, elementType)
};
const Font = (fontWeight = 'inherit', fontSize = 'inherit', elementType = 'default') => css(["font-variation-settings:'wght' ", ";font-weight:", ";letter-spacing:", ";font-size:", ";"], fontWeight, fontWeight, getLetterSpacing(fontSize, elementType), getFontSize(fontSize));
const getLetterSpacing = (fontSize, elementType) => {
  if (elementType === 'interactive') {
    return '0.8px';
  }
  if (typeof fontSize === 'number') {
    if (fontSize > 24) {
      return 'normal';
    }
    if (fontSize >= 20) {
      return '0.2px';
    }
    if (fontSize >= 12) {
      return '0.4px';
    }
  }
  return 'initial';
};
export const getFontSize = (fontSize = 'inherit') => {
  if (fontSize === 'inherit') {
    return 'inherit';
  }
  return `${fontSize}px`;
};