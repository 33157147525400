import { css } from 'styled-components';
import { Colors, hexToRGB } from './Colors';
const applyDouble = size => {
  if (size === 1) {
    return css(["box-shadow:0 0 1px 0 rgba(0,0,0,0.16),0 0 1px 0 rgba(0,0,0,0.08);"]);
  }
  return css(["box-shadow:0 ", "px ", "px 0 rgba(0,0,0,0.16),0 0 ", "px 0 rgba(0,0,0,0.08);"], size / 2, size, size);
};
const applySingle = (size = 1) => {
  if (size === 1) {
    return css(["box-shadow:0 0 1px 0 rgba(0,0,0,0.24);"]);
  }
  return css(["box-shadow:0 ", "px ", "px 0 rgba(0,0,0,0.16);"], size / 2, size);
};
export const Shadows = {
  applySingle,
  applyDouble,
  text: () => css(["text-shadow:0 1px 2px ", ";"], hexToRGB(Colors.BLACK, 0.5))
};