import { produce } from 'immer';
import { reducer as mediaSearchReducer } from '../../components/MediaSearch/media-search-reducer';
import { isPersonalizationActive } from '../../storage/personalization-settings';
import { logAction } from '../../utils/log-action';
export const initialState = {
  query: {
    searchTerm: '',
    includeAggregations: false
  },
  showAll: false,
  hideShowSearch: false,
  searchOpen: false,
  searchPageOpen: false,
  mobileFiltersOpen: false,
  showsLoading: false,
  showsFailed: false,
  mediaLoading: false,
  searchHistory: [],
  dates: [],
  dateQuickFilters: [],
  markedDates: {}
};
export const reducer = (state, action) => {
  logAction(state, action);
  const reset = draft => {
    draft.query = initialState.query;
    draft.showAll = initialState.showAll;
    draft.hideShowSearch = initialState.hideShowSearch;
    draft.mediaResult = undefined;
    draft.showResult = undefined;
  };
  return produce(state, draft => {
    switch (action.type) {
      case 'set-query-state':
        {
          draft.query = action.state.query;
          draft.showAll = action.state.showAll ?? false;
          draft.hideShowSearch = action.state.hideShowSearch ?? false;
          draft.searchOpen = true;
          return draft;
        }
      case 'search':
        {
          draft.query.searchTerm = action.query;
          return draft;
        }
      case 'toggle':
        {
          if (state.searchOpen) {
            reset(draft);
          }
          draft.searchOpen = !state.searchOpen;
          return draft;
        }
      case 'close':
        {
          if (state.searchOpen) {
            reset(draft);
            draft.searchOpen = false;
          }
          return draft;
        }
      case 'set-search-page-open':
        {
          draft.searchPageOpen = action.isOpen;
          if (!action.isOpen) {
            draft.searchOpen = false;
            draft.previousPage = undefined;
            reset(draft);
          }
          return draft;
        }
      case 'set-mobile-filters-open':
        {
          draft.mobileFiltersOpen = action.isOpen;
          if (action.isOpen) {
            draft.query.includeAggregations = true;
          }
          return draft;
        }
      case 'set-previous-page':
        {
          draft.previousPage = action.previousPage;
          return draft;
        }
      case 'include-aggregations':
        {
          draft.query.includeAggregations = true;
          return draft;
        }
      case 'search-shows':
        {
          draft.showsLoading = true;
          draft.showsFailed = false;
          return draft;
        }
      case 'search-shows-success':
        {
          draft.showsLoading = false;
          draft.showsFailed = false;
          draft.showResult = action.result;
          return draft;
        }
      case 'search-shows-failed':
        {
          draft.showsLoading = false;
          draft.showsFailed = true;
          return draft;
        }
      case 'clear-search-history':
        {
          draft.searchHistory = [];
          return draft;
        }
      case 'add-search-entry':
        {
          if (isPersonalizationActive() && action.searchTerm) {
            const entryLimit = 10;
            const unique = (storedValue, index, self) => self.findIndex(value => value.searchTerm === storedValue.searchTerm) === index;
            draft.searchHistory = [{
              searchTerm: action.searchTerm,
              date: new Date()
            }, ...draft.searchHistory].filter(unique).filter((_, i) => i < entryLimit);
          }
          return draft;
        }
      default:
        {
          const newState = mediaSearchReducer(state, action);
          Object.assign(draft, newState);
          return draft;
        }
    }
  });
};