import { loadTopics } from '../../../../play-core/api/load-video-topics';
import { generateUserId } from '../../api/generate-user-id';
export const fetchMiddleware = axios => store => next => action => {
  const stateBeforeAction = store.getState();
  const {
    dispatch
  } = store;
  next(action);
  switch (action.type) {
    case 'set-personalization':
      {
        if (action.enable) {
          dispatch({
            type: 'load-user-id'
          });
        }
        break;
      }
    case 'load-user-id':
      if (!stateBeforeAction.userIdState.isLoading) {
        generateUserId(axios).then(userIdResponse => dispatch({
          type: 'load-user-id-success',
          userId: userIdResponse.userId
        })).catch(() => dispatch({
          type: 'load-user-id-failed'
        }));
      }
      break;
    case 'load-topics':
      {
        if (!stateBeforeAction.topicState.isTopicsLoading) {
          loadTopics(axios).then(topicResponse => dispatch({
            type: 'load-topics-success',
            topics: topicResponse
          })).catch(() => dispatch({
            type: 'load-topics-failed'
          }));
        }
        break;
      }
    default:
  }
};