import { localStorage } from '../../../play-core/utils/local-storage';
import { isBrowser } from '../../../play-core/utils/ssr-helper';
import { getBusinessUnitFromLocation, getEnvironmentFromLocation } from '../utils/location-helper';

/**
 * This local storage prefixes the keys with the business unit and the environment if we're NOT
 * running on a public server (as determined by isRequestedViaCDN() in video-server-side-rendering.tsx)
 *
 * This allows to test multiple BU/environment combinations running on the same domain and therefore sharing
 * the same local storage instance.
 * */

export const playLocalStorage = (key, defaultValue) => {
  let prefixedKey = key;
  if (isBrowser && window.__PLAY__?.isRequestedViaCDN !== true) {
    prefixedKey = `${getBusinessUnitFromLocation()}-${getEnvironmentFromLocation()}-${key}`;
  }
  return localStorage(prefixedKey, defaultValue);
};