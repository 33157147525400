import { addDays, endOfDay, format, isBefore, isSameDay, startOfDay } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
export const parseToTime = timeString => {
  const [hourString, minuteString] = timeString.split(':');
  return {
    minutes: parseInt(minuteString),
    hours: parseInt(hourString)
  };
};
export const isSameDayOrBefore = (left, right) => {
  const dateLeft = new Date(left);
  const dateRight = new Date(right);
  return isSameDay(dateLeft, dateRight) || isBefore(dateLeft, dateRight);
};
export const centralEuropeanTimezone = 'Europe/Zurich';
export const ignoreTimezoneExtension = 'T00:00:00';
export const centralEuropeanNow = () => utcToCentralEuropean(new Date());

/**
 * applies central european time to date without adjust timezone
 * Example if device is in Greenwich Mean Time
 * date:    Fri Feb 24 2023 06:42:49 GMT+0000 (Greenwich Mean Time)
 * returns: Fri Feb 24 2023 07:42:49 GMT+0000 (Greenwich Mean Time)
 */
export const utcToCentralEuropean = date => toZonedTime(date, centralEuropeanTimezone);
export const startOfDayIso = (date = new Date().toISOString()) => startOfDay(new Date(date)).toISOString();
export const endOfDayIso = (date = new Date().toISOString()) => endOfDay(new Date(date)).toISOString();
export const mergeDayTimeIso = (dateDayString, time) => {
  const day = new Date(dateDayString);
  const {
    hours,
    minutes
  } = time;
  day.setHours(hours, minutes, 0, 0);
  return day.toISOString();
};
export const mergeDateIso = (dateDayString, dateTimeString) => mergeDayTimeIso(dateDayString, parseToTime(format(new Date(dateTimeString), 'HH:mm')));
export const getDateArray = (from, to) => {
  const dates = [];
  for (let date = from; isSameDayOrBefore(date.toString(), to.toString()); date = addDays(date, 1)) {
    dates.push(date);
  }
  return dates;
};