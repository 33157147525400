import { findRoute } from '../../models/PlayRoute';
export const analyticsMiddleware = (analyticsDispatch, routes, baseUrl) => store => next => action => {
  next(action);
  const state = store.getState();
  const currentPageId = findRoute(window.location.pathname.replace(baseUrl, ''), routes)?.pageId;
  switch (action.type) {
    case 'toggle-topic-favorite':
      {
        analyticsDispatch({
          type: 'click-event',
          name: 'AddTopicFavorite',
          value1: action.topicId,
          value3: currentPageId,
          value4: state.topicState.topicFavorites.includes(action.topicId).toString()
        });
        break;
      }
    case 'toggle-show-favorite':
      {
        analyticsDispatch({
          type: 'click-event',
          name: 'AddShowFavorite',
          value1: action.showId,
          value3: currentPageId,
          value4: state.showState.favoriteShowIds.includes(action.showId).toString()
        });
        break;
      }
    case 'toggle-watch-later':
      {
        analyticsDispatch({
          type: 'click-event',
          name: 'AddWatchLater',
          value1: action.urn,
          value3: currentPageId,
          value4: state.watchLaterState.videoUrns.includes(action.urn).toString()
        });
        break;
      }
    default:
  }
};