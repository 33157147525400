import produce from 'immer';
export const initialState = {};
export const reducer = (state, action) => produce(state, draft => {
  switch (action.type) {
    case 'add-snackbar':
      {
        draft.snackbar = action.snackbarEntry;
        return draft;
      }
    case 'remove-snackbar':
      {
        draft.snackbar = undefined;
        return draft;
      }
    default:
      {
        throw new Error(`Unhandled action type: ${action.type}`);
      }
  }
});