export const zIndex = {
  stickyBar: 50,
  popup: 100,
  floatingAction: 150,
  calendar: 200,
  header: 500,
  aboveHeader: 750,
  snackbar: 800,
  dialog: 1000,
  aboveDialog: 1500,
  abovePlayer: 5
};