import { useMemo } from 'react';
import { useDebugDispatch } from '../../src/components/Debug/DebugContext';
import { useVideoConfig } from '../config/VideoAppContext';
import { createAxios } from '../utils/create-axios';
export const useAxios = () => {
  const {
    businessUnit,
    environment,
    vector
  } = useVideoConfig();
  const debugDispatch = useDebugDispatch();
  return useMemo(() => createAxios(businessUnit, environment, vector, debugDispatch), [businessUnit, debugDispatch, environment, vector]);
};