export const SRFPlayLink = {
  title: 'SRF',
  url: 'https://www.srf.ch/play',
  type: 'Link'
};
export const RTRPlayLink = {
  title: 'RTR',
  url: 'https://www.rtr.ch/play',
  type: 'Link'
};
export const RSIPlayLink = {
  title: 'RSI',
  url: 'https://www.rsi.ch/play',
  type: 'Link'
};
export const RTSPlayLink = {
  title: 'RTS',
  url: 'https://www.rts.ch/play',
  type: 'Link'
};
export const SWIPlayLink = {
  title: 'SWI',
  url: 'https://www.swissinfo.ch/play',
  type: 'Link'
};
export const PlaySuisseLink = {
  title: 'Play Suisse',
  url: 'https://www.playsuisse.ch/',
  type: 'Link',
  rel: 'nofollow'
};
const buPlayLinks = [RTSPlayLink, RSIPlayLink, RTRPlayLink, SWIPlayLink, PlaySuisseLink];
export const defaultFooterConfig = {
  srgssrUrl: 'https://www.srgssr.ch/de/start/',
  buPlayLinks,
  externalLinks: [{
    title: 'Kontakt',
    url: 'https://www.srf.ch/kontakt',
    type: 'Link'
  }, {
    title: 'Podcasts',
    url: 'https://www.srf.ch/podcasts',
    type: 'Link'
  }],
  privacyLink: {
    title: 'Datenschutzerklärung',
    url: 'https://www.srf.ch/rechtliches-datenschutzerklaerung?srg_shorturl_source=rechtliches',
    type: 'Link'
  },
  privacySettings: {
    title: 'Datenschutz-Einstellungen',
    type: 'Button',
    onClick: 'ShowPrivacySettings'
  }
};