import { produce } from 'immer';
import { createUUID } from '../../../play-core/utils/uuid-helper';
import { logAction } from '../utils/log-action';

/*
 * Here are the types listed for all tracking events.
 * Add event: Add new type with the following props: name, type=click-event and the values (value1 - value4) you need.
 * Add the type to the list "ActionIntersection". It will force you to add a documentation for your new entry in the file analytics-documentation.
 * Consider the index of types, the type list here and the documentation list in the file analytics-documentation needs to be in the same order.
 * Edit event: Adjust type here, check if your entry in the file analytics-documentation.ts is still correct
 * Delete event: Delete the type and remove the type from "ActionIntersection". Remove the documentation entry from the file analytics-documentation.ts
 */

export const initialState = {
  clientSession: {
    id: createUUID(),
    timestamp: Date.now()
  }
};
export const reducer = (state, action) => {
  logAction(state, action);
  return produce(state, draft => draft);
};