export const createEventTracker = () => {
  let eventQueue = [];
  let availabilityChecker;
  const track = event => {
    if (isTagCommanderReady()) {
      trackEvent(event);
    } else {
      eventQueue.push(event);
      waitUntilTagCommanderIsReady();
    }
  };
  const waitUntilTagCommanderIsReady = () => {
    if (!availabilityChecker) {
      availabilityChecker = window.setInterval(processEventQueue, 300);
    }
  };
  const processEventQueue = () => {
    if (isTagCommanderReady()) {
      window.clearInterval(availabilityChecker);
      availabilityChecker = undefined;
      eventQueue.forEach(trackEvent);
      eventQueue = [];
    }
  };
  const trackEvent = event => {
    const tcEvent = {
      id: 'hidden_event',
      data: {
        // event_source is specified here: https://srgssr-ch.atlassian.net/wiki/spaces/SRGPLAY/pages/799081972/Play+Web+Portal+-+Analytics+Events
        event_source: '2797',
        ...event
      }
    };
    window.tc_events_31?.call(null, null, tcEvent.id, tcEvent.data);
  };
  const isTagCommanderReady = () => typeof window.tc_events_31 === 'function';
  return {
    track
  };
};