import axios from 'axios';
import { apiBasePath } from '../../../play-core/utils/base-path';
export const createAxios = (businessUnit, environment, vector, debugDispatch) => {
  const params = {
    vector
  };
  const axiosInstance = axios.create({
    baseURL: `${apiBasePath}/${businessUnit}/${environment}`,
    params
  });
  axiosInstance.interceptors.response.use(response => {
    dispatchDebugInfo(response, debugDispatch);
    return response;
  }, error => {
    dispatchDebugInfo(error.response, debugDispatch);
    throw error;
  });
  return axiosInstance;
};
const dispatchDebugInfo = (response, debugDispatch) => {
  if (debugDispatch && response.config && response.headers && response.data && (response.data.ilRequests || response.data.failedIlRequests)) {
    debugDispatch({
      type: 'log-il-request',
      requests: {
        method: response.config.method,
        url: response.config.url,
        timestamp: new Date().toISOString(),
        responseTime: response.headers['x-response-time'],
        ilRequests: response.data.ilRequests || [],
        failedIlRequests: response.data.failedIlRequests || []
      }
    });
  }
};