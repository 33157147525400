export const equals = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  return isSuperSet(arr1, arr2);
};
export const isSuperSet = (arr1, arr2) => arr2.every(arr2Item => arr1.includes(arr2Item));
export const intersection = (arr1, arr2) => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    return [];
  }
  return arr1.filter(x => arr2.includes(x));
};
export const toggleEntry = (array, entry) => {
  if (array.includes(entry)) {
    return array.filter(currentEntry => !Object.is(currentEntry, entry));
  }
  return [entry, ...array];
};
export const uniqueById = array => {
  const addedIds = [];
  return array.reduce((accumulator, current) => {
    if (addedIds.indexOf(String(current.id)) >= 0) {
      return accumulator;
    }
    addedIds.push(String(current.id));
    accumulator.push(current);
    return accumulator;
  }, []);
};
export const unique = array => [...new Set(array)];
export const reorder = (array, keyValue, index, key) => {
  if (!array) {
    return [];
  }
  const itemIndex = array?.findIndex(value => value[key] === keyValue);
  if (itemIndex === index) {
    return array;
  }
  if (itemIndex === -1) {
    return array;
  }
  // create new instance for mutation;
  const newArray = [...array];
  const [item] = newArray.splice(itemIndex, 1);
  newArray.splice(index, 0, item);
  return newArray;
};
export const moveElement = (inputArray, element, direction) => {
  const array = [...inputArray];
  const index = array.indexOf(element);
  if (index !== -1) {
    const newPosition = direction === 'Up' ? Math.max(index - 1, 0) : Math.min(index + 1, array.length - 1);
    array.splice(index, 1);
    array.splice(newPosition, 0, element);
  }
  return array;
};
export const mapByKey = (array, theKey) => {
  const result = {};
  array?.forEach(entry => {
    const key = entry[theKey];
    if (typeof key === 'string') {
      result[key] = entry;
    }
  });
  return result;
};
export const hasMoreThan = (array, nrOfElements) => !!array?.length && array.length > nrOfElements;
export const chunk = (arr, size) => {
  if (size <= 0) {
    throw new Error('Chunk size must be greater than 0');
  }
  const result = [];
  for (let i = 0; i < arr.length; i += size) {
    result.push(arr.slice(i, size + i));
  }
  return result;
};