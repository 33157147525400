import { playLocalStorage } from './play-local-storage';
const storageKey = 'playSRG-favShows';
const defaultValue = [];
const get = () => {
  const {
    getItem
  } = playLocalStorage(storageKey, defaultValue);
  return toPublicFormat(getItem());
};
const set = favorites => {
  const {
    setItem
  } = playLocalStorage(storageKey, defaultValue);
  const storedValue = setItem(toStorageFormat(favorites));
  return toPublicFormat(storedValue);
};
const reset = () => {
  const {
    setItem
  } = playLocalStorage(storageKey, defaultValue);
  const storedValue = setItem(defaultValue);
  return toPublicFormat(storedValue);
};
export const favoriteShowsStorage = {
  get,
  set,
  reset
};
const toStorageFormat = favorites => favorites.map(id => ({
  id
}));
const toPublicFormat = favorites => favorites.map(favorite => favorite.id);