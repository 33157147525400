import { toSeoString } from '../../../../play-core/utils/to-seo-string';
const pageSection = 'tv';
const basePath = i18n => `/${pageSection}/${i18n.t('routes.topics')}`;
export const topicRoute = i18n => ({
  pageId: 'topic',
  pageType: 'overview',
  pageSection,
  path: `${basePath(i18n)}/:topicName`,
  remixRoute: 'TopicPage.remix-route.tsx',
  showScrollTop: true
});
export const topicPath = i18n => topicName => `${basePath(i18n)}/${toSeoString(topicName)}`;
export const isSelectedTopicRoute = (route, topicTitle, selectedTopicNameParam) => selectedTopicNameParam === toSeoString(topicTitle) && route.pageId === 'topic';