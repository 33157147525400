export const createRequestUrl = (requestUrl, params = {}) => `${requestUrl}${toQueryString(params)}`;
export const toQueryString = (queryStringObject = {}) => {
  const params = new URLSearchParams();
  Object.entries(queryStringObject).forEach(([key, value]) => {
    if (typeof value === 'string') {
      params.append(key, value);
    } else if (typeof value === 'boolean') {
      params.append(key, value.toString());
    } else if (typeof value === 'number') {
      params.append(key, `${value}`);
    } else if (Array.isArray(value) && value.length > 0) {
      params.append(key, `${value.join(',')}`);
    }
  });
  const stringParams = params.toString();
  return stringParams.length > 0 ? `?${stringParams}` : '';
};
export const toSearchQueryString = searchQueryObject => {
  const searchQuery = {
    ...searchQueryObject
  };
  Object.entries(searchQuery).forEach(([key, value]) => {
    if (typeof value === 'string' && key !== 'searchTerm' && !value) {
      searchQuery[key] = undefined;
    }
  });
  return toQueryString(searchQuery);
};