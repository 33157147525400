import { playLocalStorage } from './play-local-storage';
const storageKey = 'playSRG-playChannelHistory';
const defaultValue = [];
const get = () => {
  const {
    getItem
  } = playLocalStorage(storageKey, defaultValue);
  return getItem();
};
const set = channels => {
  const {
    setItem
  } = playLocalStorage(storageKey, defaultValue);
  setItem(channels);
};
const reset = () => {
  const {
    setItem
  } = playLocalStorage(storageKey, defaultValue);
  return setItem(defaultValue);
};
export const channelHistoryStorage = {
  get,
  set,
  reset
};