import { playLocalStorage } from '../../../storage/play-local-storage';
const storageKey = 'playSRG-search';
const defaultValue = {
  localHistory: []
};
const mapToSearchHistory = searchStorage => {
  const {
    localHistory
  } = searchStorage;
  return localHistory;
};
const get = () => {
  const {
    getItem
  } = playLocalStorage(storageKey, defaultValue);
  return mapToSearchHistory(getItem());
};
const set = searchHistoryEntries => {
  const {
    setItem
  } = playLocalStorage(storageKey, defaultValue);
  const newSearchHistory = setItem({
    localHistory: searchHistoryEntries
  });
  return mapToSearchHistory(newSearchHistory);
};
const reset = () => {
  const {
    setItem
  } = playLocalStorage(storageKey, defaultValue);
  return mapToSearchHistory(setItem(defaultValue));
};
export const searchHistoryStorage = {
  get,
  set,
  reset
};