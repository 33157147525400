import { useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { pageViewTrackingDelay } from './analytics.config';
import { useVideoConfig } from '../config/VideoAppContext';
import { getAbGroup } from '../hooks/use-ab-test';
import { useAnalyticsId } from '../privacy/PrivacyProvider/PrivacyContext';
import { userGroupStorage } from '../storage/user-group-storage';
export const useAbTestTracking = dispatch => {
  const {
    abTesting
  } = useVideoConfig();
  const cookieId = useAnalyticsId();
  const {
    groupNumber
  } = userGroupStorage.get();
  const abTests = Object.entries(abTesting);

  /**
   * Cold start detection is unreliable due to multiple scenarios:
   * 1. `user_id === undefined` → Cold start (certain).
   * 2. Insufficient tracking data for `user_id` → Cold start (uncertain).
   * 3. Sufficient tracking data exists, but nightly model training hasn't run yet → Cold start (uncertain).
   *
   * Except for scenario 1, the frontend cannot reliably determine if it's a cold start.
   * This behavior was explicitly requested by Regula Nobel on Jan 30, 2025.
   */
  const scenario = cookieId ? 'Recommendation' : 'Cold-Start';
  const trackAbTests = useDebouncedCallback(() => {
    abTests.forEach(([key, value]) => {
      dispatch({
        type: 'ab-testing-config',
        testName: key,
        group: getAbGroup(groupNumber, value.groupA, value.groupB),
        scenario: key === 'recommender' ? scenario : undefined
      });
    });
  }, pageViewTrackingDelay);
  useEffect(() => {
    trackAbTests();
  }, [abTests, dispatch, groupNumber, trackAbTests]);
};