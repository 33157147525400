import { differenceInCalendarDays, endOfToday, endOfYesterday, isToday, isValid, isWithinInterval, isYesterday, startOfToday, subDays } from 'date-fns';
import { isoDate, shortDateFormat } from '../../utils/date-formatter';
import { areRangesEqual } from '../DatePicker/date-picker-helper';
const today = endOfToday();
const yesterday = endOfYesterday();
export const createDateQuickFilters = (dates, selectedRange) => {
  const last365Medias = getMediasOfPastDays(dates, 365);
  const last31Medias = getMediasOfPastDays(last365Medias, 31);
  const last7Medias = getMediasOfPastDays(last31Medias, 7);
  const todayMedias = getMediasOfPastDays(last7Medias, 0);
  const yesterdayMedias = last7Medias.filter(dateAggregationItem => isYesterday(new Date(dateAggregationItem.date)));
  const items = [{
    count: sumCount(todayMedias),
    ...toDateRange(today, 0)
  }, {
    count: sumCount(yesterdayMedias),
    ...toDateRange(yesterday, 0)
  }, {
    count: sumCount(last7Medias),
    ...toDateRange(today, 7)
  }, {
    count: sumCount(last31Medias),
    ...toDateRange(today, 31)
  }, {
    count: sumCount(last365Medias),
    ...toDateRange(today, 365)
  }].map(item => ({
    ...item,
    isSelected: areRangesEqual(selectedRange, {
      from: isoDate(item.from),
      to: isoDate(item.to)
    }),
    textKey: getDateFilterName(item.from, item.to)
  })).filter(item => item.count > 0 || item.isSelected);
  return items;
};
export const getDateFilterName = (from, to) => {
  if (from && to) {
    const fromDate = new Date(from);
    const toDate = new Date(to);
    if (isToday(toDate)) {
      switch (differenceInCalendarDays(toDate, fromDate)) {
        case 0:
          return 'global.today';
        case 7:
          return 'search.dateRange.last7Days';
        case 31:
          return 'search.dateRange.last31Days';
        case 365:
          return 'search.dateRange.last365Days';
        default:
          return `${shortDateFormat(from)} - ${shortDateFormat(to)}`;
      }
    } else if (isYesterday(toDate) && isYesterday(fromDate)) {
      return 'global.yesterday';
    } else if (isValid(fromDate) && isValid(toDate)) {
      return `${shortDateFormat(from)} - ${shortDateFormat(to)}`;
    }
  }
  return 'Unknown';
};
const getMediasOfPastDays = (dateAggregationItems, days) => {
  const startDate = subDays(startOfToday(), days);
  return dateAggregationItems.filter(dateAggregationItem => isWithinInterval(new Date(dateAggregationItem.date), {
    start: startDate,
    end: today
  }));
};
const sumCount = dates => dates.reduce((acc, cur) => acc + cur.count, 0);
const toDateRange = (to, diffInDays) => ({
  to: isoDate(to),
  from: isoDate(subDays(to, diffInDays))
});