import { cookies } from './cookies';
import { isBrowser } from '../../../play-core/utils/ssr-helper';
import { createUUID, validateUUID } from '../../../play-core/utils/uuid-helper';
const storageKey = 'srf:analytics:uuid';

// make sure cookie and localstorage is set and return value
const get = () => {
  if (isBrowser) {
    try {
      const itemLocalStorage = window.localStorage.getItem(storageKey) ?? undefined;
      const {
        get: getCookie,
        set: setCookie
      } = cookies(storageKey);
      const itemCookie = getCookie();
      if (!itemLocalStorage && !itemCookie) {
        return reset();
      }
      if (!itemLocalStorage && itemCookie) {
        set(itemCookie);
      } else if (itemLocalStorage && !itemCookie) {
        setCookie(itemLocalStorage);
      }
      return validate(itemLocalStorage ?? itemCookie);
    } catch {
      // ignore
    }
  }
};

// if cookie is already set with value --> use existing
const set = analyticsId => {
  if (isBrowser) {
    try {
      window.localStorage.setItem(storageKey, analyticsId);
      const {
        set: setCookie
      } = cookies(storageKey);
      setCookie(analyticsId);
      return analyticsId;
    } catch {
      // ignore
    }
  }
};
const reset = () => {
  if (isBrowser) {
    try {
      return set(createUUID());
    } catch {
      // ignore
    }
  }
  return undefined;
};
const validate = (analyticsId = '') => {
  const isValid = validateUUID(analyticsId);
  if (isValid) {
    return analyticsId;
  }
  return reset();
};
export const udpAnalyticsIdStorage = {
  set,
  get,
  reset
};