import { playLocalStorage } from './play-local-storage';
const storageKey = 'playSRG-debugLogging';
const defaultValue = {
  isActive: false
};
let isLoggingActive;
const get = () => {
  if (isLoggingActive === undefined) {
    const {
      getItem
    } = playLocalStorage(storageKey, defaultValue);
    isLoggingActive = getItem().isActive;
  }
  return isLoggingActive;
};
const set = isActive => {
  const {
    setItem
  } = playLocalStorage(storageKey, defaultValue);
  setItem({
    isActive
  });
  isLoggingActive = isActive;
  return isActive;
};
export const debugLoggingStorage = {
  get,
  set
};