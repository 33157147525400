import { useVideoConfig } from '../config/VideoAppContext';
import { userGroupStorage } from '../storage/user-group-storage';

// explanation for "-?": https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-8.html#example-5

export const getAbGroup = (groupNumber, percentageForA, percentageForB) => {
  if (percentageForA >= groupNumber) {
    return 'A';
  }
  if (percentageForB || percentageForB === 0) {
    if (percentageForA + percentageForB >= groupNumber) {
      return 'B';
    }
    return 'C';
  }
  return 'B';
};
export const useAbTest = value => {
  const {
    groupNumber
  } = userGroupStorage.get();
  const {
    abTesting
  } = useVideoConfig();
  if (!value) return 'A';
  const percentageForA = abTesting[value].groupA;
  const percentageForB = abTesting[value].groupB;
  return getAbGroup(groupNumber, percentageForA, percentageForB);
};