import { css } from 'styled-components';
import { Colors, hexWithOpacity } from '../../../../play-core/styles/Colors';
import { Device } from '../../../../play-core/styles/Device';
import { Fonts } from '../../../../play-core/styles/Fonts';
import { Viewports } from '../../../../play-core/styles/Viewports';

// eslint-disable-next-line @typescript-eslint/no-explicit-any

export const defaultHorizontalPadding = 12;
const boxModelBase = css(["padding:8px ", "px;height:40px;min-height:40px;"], defaultHorizontalPadding);
export const boxModelBySize = {
  default: css(["", " ", "{height:48px;min-height:48px;}"], boxModelBase, Viewports.for('mediumUp')),
  small: css(["", " ", "{height:42px;min-height:42px;}"], boxModelBase, Viewports.for('mediumUp')),
  large: css(["", " ", "{height:48px;min-height:48px;}"], boxModelBase, Viewports.for('mediumUp'))
};
export const fontBySize = {
  default: {
    mediaQuery: [],
    default: 16
  },
  small: {
    mediaQuery: [],
    default: 14
  },
  large: {
    mediaQuery: [],
    default: 18
  }
};
const responsiveFontSize = css(["", ""], ({
  fontSize
}) => `font-size: ${fontSize.default}px;
  ${fontSize.mediaQuery.map(query => `${Viewports.for(query[0])} {
      font-size: ${query[1]}px;
    }`)}`);
export const responsiveFontType = css(["", ""], ({
  fontSize,
  fontType
}) => css(["", ";", ""], Fonts.byType(fontType, fontSize.default), fontSize.mediaQuery.map(query => `${Viewports.for(query[0])} {
            ${Fonts.byType(fontType, query[1])}
        }`)));
const disabledStyle = css(["pointer-events:none;"]);
export const baseStyle = css(["", ";", ";color:", ";font-variant-numeric:tabular-nums;letter-spacing:0.8px;white-space:", ";display:grid;grid-auto-flow:column;grid-column-gap:", "px;align-items:center;justify-content:", ";transition:background-color 0.2s ease-in;width:", ";", ";background-color:", ";height:", ";border-radius:2px;", " ", "{&:hover{background-color:", ";color:", ";", "}}&:active{background-color:", ";}", ""], responsiveFontSize, responsiveFontType, ({
  colorSet,
  isActive,
  isDisabled
}) => {
  switch (true) {
    case isActive:
      return colorSet.activeColor ?? colorSet.color;
    case isDisabled:
      return colorSet.disabledColor || (colorSet.color ? hexWithOpacity(colorSet.color, 50) : undefined);
    default:
      return colorSet.color;
  }
}, ({
  multilineText
}) => multilineText ? 'pre-wrap' : 'nowrap', ({
  spaceBetween
}) => spaceBetween ?? 10, ({
  leftAligned
}) => leftAligned ? 'start' : 'center', ({
  expand
}) => expand && '100%', ({
  size
}) => boxModelBySize[size], ({
  colorSet,
  isActive,
  isDisabled
}) => {
  switch (true) {
    case isActive:
      return colorSet.activeBackgroundColor ?? colorSet.backgroundColor;
    case isDisabled:
      return colorSet.disabledBackgroundColor ?? colorSet.backgroundColor;
    default:
      return colorSet.backgroundColor;
  }
}, ({
  multilineText
}) => multilineText && 'auto', ({
  isActive,
  isDisabled,
  colorSet
}) => {
  if (isActive && !!colorSet.activeIconColor) return `svg { color: ${colorSet.activeIconColor}; }`;
  if (isDisabled && !!colorSet.disabledIconColor) return `svg { color: ${colorSet.disabledIconColor}; }`;
}, Device.mouse, ({
  colorSet
}) => colorSet.hoverBackgroundColor ?? colorSet.backgroundColor, ({
  colorSet
}) => colorSet.hoverColor ?? colorSet.color, ({
  colorSet
}) => {
  if (colorSet.hoverIconColor || colorSet.hoverColor) {
    return `svg { color: ${colorSet.hoverIconColor || colorSet.hoverColor}; }`;
  }
}, ({
  colorSet
}) => colorSet.activeBackgroundColor ?? colorSet.backgroundColor, ({
  isDisabled
}) => isDisabled && disabledStyle);
export const colorSets = {
  primary: {
    color: Colors.GREY_232323,
    iconColor: Colors.GREY_232323,
    backgroundColor: Colors.GREY_d2d2d2,
    activeBackgroundColor: Colors.GREY_e1e1e1,
    activeColor: Colors.GREY_232323,
    activeIconColor: Colors.GREY_232323,
    hoverBackgroundColor: Colors.GREY_e1e1e1,
    hoverColor: Colors.GREY_232323,
    hoverIconColor: Colors.GREY_232323,
    disabledBackgroundColor: Colors.GREY_e1e1e1,
    disabledColor: hexWithOpacity(Colors.GREY_515151, 50),
    disabledIconColor: hexWithOpacity(Colors.GREY_515151, 50)
  },
  secondary: {
    color: Colors.WHITE,
    iconColor: Colors.WHITE,
    backgroundColor: Colors.GREY_3a3a3a,
    activeBackgroundColor: Colors.GREY_4a4a4a,
    activeColor: Colors.WHITE,
    activeIconColor: Colors.WHITE,
    hoverBackgroundColor: Colors.GREY_4a4a4a,
    hoverColor: Colors.WHITE,
    hoverIconColor: Colors.WHITE,
    disabledBackgroundColor: Colors.GREY_2a2a2a,
    disabledColor: hexWithOpacity(Colors.WHITE, 50),
    disabledIconColor: hexWithOpacity(Colors.WHITE, 50)
  },
  transparent: {
    color: Colors.GREY_d2d2d2,
    iconColor: Colors.GREY_d2d2d2,
    backgroundColor: 'transparent',
    activeBackgroundColor: Colors.GREY_4a4a4a,
    activeColor: Colors.GREY_d2d2d2,
    activeIconColor: Colors.GREY_d2d2d2,
    hoverBackgroundColor: Colors.GREY_4a4a4a,
    hoverColor: Colors.GREY_d2d2d2,
    hoverIconColor: Colors.GREY_d2d2d2,
    disabledBackgroundColor: Colors.GREY_4a4a4a,
    disabledColor: hexWithOpacity(Colors.GREY_d2d2d2, 50),
    disabledIconColor: hexWithOpacity(Colors.GREY_d2d2d2, 50)
  },
  alert: {
    color: Colors.WHITE,
    iconColor: Colors.WHITE,
    backgroundColor: Colors.RED_cc001a,
    activeBackgroundColor: Colors.RED_d50000,
    activeColor: Colors.WHITE,
    activeIconColor: Colors.WHITE,
    hoverBackgroundColor: Colors.RED_d50000,
    hoverColor: Colors.WHITE,
    hoverIconColor: Colors.WHITE,
    disabledBackgroundColor: Colors.RED_cc001a,
    disabledColor: hexWithOpacity(Colors.WHITE, 50),
    disabledIconColor: hexWithOpacity(Colors.WHITE, 50)
  },
  toggle: {
    color: Colors.GREY_969696,
    hoverColor: 'inherit',
    activeColor: Colors.WHITE,
    iconColor: Colors.GREY_969696,
    hoverIconColor: 'inherit',
    activeIconColor: Colors.WHITE,
    backgroundColor: 'transparent',
    hoverBackgroundColor: Colors.GREY_4a4a4a,
    activeBackgroundColor: Colors.GREY_4a4a4a,
    disabledBackgroundColor: 'transparent',
    disabledColor: hexWithOpacity(Colors.GREY_969696, 50),
    disabledIconColor: hexWithOpacity(Colors.GREY_969696, 50)
  }
};