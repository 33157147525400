import { playLocalStorage } from './play-local-storage';
const storageKey = 'playSRG-playUserIdv2';
const defaultValue = {};
const mapToUserIdString = userId => userId.userId;
const get = () => {
  const {
    getItem
  } = playLocalStorage(storageKey, defaultValue);
  const userId = mapToUserIdString(getItem());
  return validate(userId);
};
const set = userId => {
  const {
    setItem
  } = playLocalStorage(storageKey, defaultValue);
  return mapToUserIdString(setItem({
    userId
  }));
};
const reset = () => {
  const {
    setItem
  } = playLocalStorage(storageKey, defaultValue);
  return mapToUserIdString(setItem(defaultValue));
};
const validate = userId => {
  const isInvalid = userId && !userId.startsWith('$');
  if (isInvalid) {
    return reset();
  }
  return userId;
};
export const userIdStorage = {
  get,
  set,
  reset
};