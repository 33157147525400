/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsForRegex": ["^draft", "^state$"] }] */
import { produce } from 'immer';
import { noopTopicsSort, sortTopicsByFavorites } from '../../../../play-core/models/Topic';
import { toggleEntry, unique } from '../../../../play-core/utils/array-helper';
import { favoriteTopicsStorage } from '../../storage/favorite-topics-storage';
import { logAction } from '../../utils/log-action';
export const createInitialState = (topics = []) => {
  const initialState = {
    ...defaultState,
    topicState: {
      ...defaultState.topicState,
      topics,
      topicFavorites: favoriteTopicsStorage.get()
    }
  };
  setUserTopics(initialState, topics);
  return initialState;
};
export const defaultState = {
  watchLaterState: {
    videoUrns: [],
    videos: []
  },
  showState: {
    favoriteShowIds: []
  },
  topicState: {
    topics: [],
    isTopicsLoading: false,
    loadTopicsSuccess: false,
    loadTopicsFailed: false,
    userTopics: [],
    topicFavorites: []
  },
  userIdState: {
    loadFailed: false,
    loadSuccess: false,
    isLoading: false,
    userId: undefined
  },
  personalization: {
    showHeaderMessage: true,
    isActive: true
  },
  favoriteGuideState: {
    showFavoriteGuide: true
  },
  subtitleState: {
    showSubtitle: false
  },
  loginState: {
    isLoggedIn: false,
    isLoading: false,
    loadFailed: false,
    loadSuccess: false,
    profile: {}
  },
  channelHistory: []
};
const CHANNEL_HISTORY_MAX_ENTRIES = 3;
export const reducer = (state, action) => {
  logAction(state, action);
  return produce(state, draft => {
    switch (action.type) {
      case 'initialize-personalization-data':
        draft.personalization = action.settings;
        draft.showState.favoriteShowIds = action.favoriteShowIds;
        draft.topicState.topicFavorites = action.favoriteTopicIds;
        draft.watchLaterState.videoUrns = action.watchLaterUrns;
        draft.subtitleState.showSubtitle = action.showSubtitle;
        draft.favoriteGuideState.showFavoriteGuide = action.showFavoriteGuide;
        draft.channelHistory = action.channelHistory;
        limitFavoriteShows(draft);
        limitWatchLater(draft);
        updateUserTopics(draft);
        return draft;
      case 'set-personalization':
        {
          draft.personalization.isActive = action.enable;
          if (!action.enable) {
            draft.watchLaterState = defaultState.watchLaterState;
            draft.topicState.topicFavorites = [];
            draft.showState.favoriteShowIds = [];
            draft.userIdState = defaultState.userIdState;
            draft.subtitleState = defaultState.subtitleState;
            draft.favoriteGuideState = defaultState.favoriteGuideState;
            draft.channelHistory = defaultState.channelHistory;
            updateUserTopics(draft);
          }
          return draft;
        }
      case 'set-favorite-guide':
        {
          draft.favoriteGuideState.showFavoriteGuide = action.favoriteGuide;
          return draft;
        }
      case 'add-channel-history':
        {
          if (draft.personalization.isActive) {
            const prependedWithNewChannelId = [action.channel.id, ...draft.channelHistory];
            draft.channelHistory = unique(prependedWithNewChannelId).slice(0, CHANNEL_HISTORY_MAX_ENTRIES);
          }
          return draft;
        }
      case 'load-topics-success':
        {
          draft.topicState.isTopicsLoading = false;
          draft.topicState.loadTopicsFailed = false;
          draft.topicState.loadTopicsSuccess = true;
          draft.topicState.topics = action.topics;
          setUserTopics(draft, action.topics);
          return draft;
        }
      case 'load-topics-failed':
        {
          draft.topicState.isTopicsLoading = false;
          draft.topicState.loadTopicsFailed = true;
          draft.topicState.loadTopicsSuccess = false;
          return draft;
        }
      case 'toggle-topic-favorite':
        {
          draft.topicState.topicFavorites = toggleEntry(draft.topicState.topicFavorites, action.topicId);
          updateUserTopics(draft, false);
          return draft;
        }
      case 'set-topic-favorites':
        {
          draft.topicState.topicFavorites = action.topicFavorites;
          updateUserTopics(draft, false);
          return draft;
        }
      case 'toggle-show-favorite':
        {
          draft.showState.favoriteShowIds = toggleEntry(draft.showState.favoriteShowIds, action.showId);
          limitFavoriteShows(draft);
          return draft;
        }
      case 'clear-show-favorites':
        {
          draft.showState.favoriteShowIds = [];
          return draft;
        }
      case 'undo-clear-show-favorites':
        {
          draft.showState = action.showState;
          return draft;
        }
      case 'toggle-watch-later':
        {
          draft.watchLaterState.videoUrns = toggleEntry(draft.watchLaterState.videoUrns, action.urn);
          draft.watchLaterState.videos = draft.watchLaterState.videos.filter(video => draft.watchLaterState.videoUrns.includes(video.urn));
          limitWatchLater(draft);
          return draft;
        }
      case 'set-watch-later-urns':
        {
          draft.watchLaterState.videoUrns = action.videoUrns;
          draft.watchLaterState.videos = draft.watchLaterState.videos.filter(video => draft.watchLaterState.videoUrns.includes(video.urn));
          limitWatchLater(draft);
          return draft;
        }
      case 'set-watch-later-videos':
        {
          draft.watchLaterState.videoUrns = action.videos.map(video => video.urn);
          draft.watchLaterState.videos = action.videos;
          return draft;
        }
      case 'clear-watch-later':
        {
          draft.watchLaterState = defaultState.watchLaterState;
          return draft;
        }
      case 'undo-clear-watch-later':
        {
          draft.watchLaterState = action.watchLater;
          return draft;
        }
      case 'load-user-id':
        {
          draft.userIdState.isLoading = true;
          return draft;
        }
      case 'load-user-id-success':
        {
          draft.userIdState.userId = action.userId;
          draft.userIdState.loadFailed = false;
          draft.userIdState.loadSuccess = true;
          draft.userIdState.isLoading = false;
          return draft;
        }
      case 'load-user-id-failed':
        {
          draft.userIdState.loadFailed = true;
          draft.userIdState.loadSuccess = false;
          draft.userIdState.isLoading = false;
          return draft;
        }
      case 'load-topics':
        {
          draft.topicState.isTopicsLoading = true;
          return draft;
        }
      case 'toggle-show-subtitle':
        {
          draft.subtitleState.showSubtitle = !draft.subtitleState.showSubtitle;
          return draft;
        }
      case 'set-logged-in':
        {
          draft.loginState.isLoggedIn = action.isLoggedIn;
          if (!action.isLoggedIn) {
            draft.watchLaterState = defaultState.watchLaterState;
            draft.showState = defaultState.showState;
            draft.topicState.topicFavorites = [];
            draft.loginState = defaultState.loginState;
          }
          return draft;
        }
      case 'set-profile':
        {
          draft.loginState.profile = action.profile;
          return draft;
        }
      default:
        {
          return draft;
        }
    }
  });
};
const setUserTopics = (draft, topics) => {
  draft.topicState.userTopics = topics.map(topic => ({
    ...topic,
    isFavorite: false
  }));
  updateUserTopics(draft);
};
const updateUserTopics = (draft, shouldOrder = true) => {
  draft.topicState.userTopics = draft.topicState.userTopics.map(topic => ({
    ...topic,
    isFavorite: draft.topicState.topicFavorites.includes(topic.id)
  })).sort(shouldOrder ? sortTopicsByFavorites : noopTopicsSort);
};
const limitFavoriteShows = ({
  showState: state
}) => {
  const maxFavoriteShows = 50;
  state.favoriteShowIds = state.favoriteShowIds.slice(0, maxFavoriteShows);
};
const limitWatchLater = ({
  watchLaterState: state
}) => {
  const maxWatchLater = 50;
  state.videoUrns = state.videoUrns.slice(0, maxWatchLater);
  state.videos = state.videos.slice(0, maxWatchLater);
};