import { useCallback, useEffect, useMemo } from 'react';
import { useVideoConfig } from '../../config/VideoAppContext';
import { isPersonalizationActive } from '../../storage/personalization-settings';
import { createRtsAxios, isRtsUserLoggedIn, loadRtsProfile, loadRtsWatchLater, logoutRtsUser } from '../../utils/rts-login-helper';
export const useRtsLogin = (state, dispatch) => {
  const {
    loginSupported
  } = useVideoConfig();
  const axios = useMemo(() => createRtsAxios(() => dispatch({
    type: 'set-logged-in',
    isLoggedIn: false
  })), [dispatch]);
  const reloadRtsProfileData = useCallback(() => {
    if (loginSupported) {
      const isLoggedIn = isRtsUserLoggedIn();
      if (isLoggedIn) {
        dispatch({
          type: 'set-logged-in',
          isLoggedIn
        });
        loadRtsWatchLater(axios).then(videoUrns => dispatch({
          type: 'set-watch-later-urns',
          videoUrns
        }));
        loadRtsProfile(axios).then(profile => dispatch({
          type: 'set-profile',
          profile
        }));
        if (!isPersonalizationActive()) {
          dispatch({
            type: 'set-personalization',
            enable: true
          });
        }
      }
    }
  }, [loginSupported, axios, dispatch]);
  useEffect(() => reloadRtsProfileData(), [reloadRtsProfileData]);
  useEffect(() => {
    if (loginSupported) {
      // If the page is shown again and the state has been persisted by the browser, reload all login related
      // data to make sure the page is up to date.
      // This can happen when the user navigates back from the maRTS profile page and browsers try to be clever and
      // restore the JavaScript state from the previous session.
      window.onpageshow = event => {
        if (event.persisted) {
          reloadRtsProfileData();
        }
      };
    }
  }, [loginSupported, reloadRtsProfileData]);
  useEffect(() => {
    // in case:
    // 1. the user is on the personalization-page and logs on.
    // 2. User gets back to personalization-page (automatically)
    // 3. User will deactivate personalization --> user gets logged out
    const isLoggedIn = isRtsUserLoggedIn();
    if (isLoggedIn && !state.personalization.isActive) {
      dispatch({
        type: 'set-logged-in',
        isLoggedIn: false
      });
      logoutRtsUser();
    }
  }, [dispatch, state.personalization.isActive]);
};