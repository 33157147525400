import { playLocalStorage } from './play-local-storage';
const storageKey = 'playSRG-favoriteGuide';
const defaultValue = {
  showFavoriteGuide: true
};
const get = () => {
  const {
    getItem
  } = playLocalStorage(storageKey, defaultValue);
  return getItem().showFavoriteGuide;
};
const set = showFavoriteGuide => {
  const {
    setItem
  } = playLocalStorage(storageKey, defaultValue);
  return setItem({
    showFavoriteGuide
  });
};
const reset = () => {
  const {
    setItem
  } = playLocalStorage(storageKey, defaultValue);
  return setItem(defaultValue);
};
export const favoriteGuideStorage = {
  get,
  set,
  reset
};