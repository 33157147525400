import { playLocalStorage } from './play-local-storage';
const storageKey = 'playSRG-playVHistory';
const defaultValue = {
  history: []
};
const get = () => {
  const {
    getItem
  } = playLocalStorage(storageKey, defaultValue);
  return toVideoProgress(getItem());
};
const set = videos => {
  const {
    setItem
  } = playLocalStorage(storageKey, defaultValue);
  const videoHistory = toVideoHistory(videos);
  return toVideoProgress(setItem(videoHistory));
};
const reset = () => {
  const {
    setItem
  } = playLocalStorage(storageKey, defaultValue);
  return toVideoProgress(setItem(defaultValue));
};
export const videoHistoryStorage = {
  get,
  set,
  reset
};
const toVideoProgress = ({
  history
}) => history.map(entry => ({
  id: entry.id,
  timestamp: entry.ts,
  progressInSeconds: entry.position,
  progressInPercentage: entry.vf
}));
const toVideoHistory = entries => {
  const history = entries.map(entry => ({
    id: entry.id,
    ts: entry.timestamp,
    position: entry.progressInSeconds,
    vf: entry.progressInPercentage || 0
  }));
  return {
    history
  };
};