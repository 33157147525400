import { playLocalStorage } from './play-local-storage';
const storageKey = 'playSRG-watchLater';
const defaultValue = {
  videos: []
};
const mapToWatchLaterVideos = ({
  videos
}) => videos;
const get = () => {
  const {
    getItem
  } = playLocalStorage(storageKey, defaultValue);
  return mapToWatchLaterVideos(getItem());
};
const set = videos => {
  const {
    setItem
  } = playLocalStorage(storageKey, defaultValue);
  return mapToWatchLaterVideos(setItem({
    videos
  }));
};
const reset = () => {
  const {
    setItem
  } = playLocalStorage(storageKey, defaultValue);
  return mapToWatchLaterVideos(setItem(defaultValue));
};
export const watchLaterStorage = {
  get,
  set,
  reset
};