export const VideoTopicApiPath = '/topics';
export const loadTopics = async axios => {
  const {
    data
  } = await axios.get(VideoTopicApiPath);
  return data.data;
};
export const loadTopicsByUserId = async (axios, userId) => {
  const {
    data
  } = await axios.get(`${VideoTopicApiPath}/${userId}`);
  return data.data;
};