import { favoriteShowsStorage } from '../../storage/favorite-shows-storage';
import { favoriteTopicsStorage } from '../../storage/favorite-topics-storage';
export const favoriteStorageMiddleware = store => next => action => {
  next(action);
  const state = store.getState();
  switch (action.type) {
    case 'set-topic-favorites':
    case 'toggle-topic-favorite':
      {
        favoriteTopicsStorage.set(state.topicState.topicFavorites);
        break;
      }
    case 'toggle-show-favorite':
    case 'clear-show-favorites':
    case 'undo-clear-show-favorites':
      {
        favoriteShowsStorage.set(state.showState.favoriteShowIds);
        break;
      }
    default:
  }
};