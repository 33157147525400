import { isBrowser } from '../../../play-core/utils/ssr-helper';
export const cookies = name => {
  const set = (value, daysToExpire = 3650) => {
    const milliSeconds = daysToExpire * 24 * 60 * 60 * 1000;
    const expireDate = new Date(milliSeconds + Date.now()).toUTCString();
    if (isBrowser) {
      document.cookie = `${name}=${value}; expires=${expireDate}; samesite=Lax; path=/`;
    }
  };
  const reset = () => {
    if (isBrowser) {
      document.cookie = `${name}=; max-age=0; path=/`;
    }
  };
  const get = () => {
    if (isBrowser) {
      const allCookies = document.cookie;
      const [, value] = allCookies.split(';').map(entry => {
        const trimmedEntry = entry.trim();
        return trimmedEntry.split('=');
      }).find(([key]) => name === key) || [];
      return value;
    }
  };
  return {
    set,
    reset,
    get
  };
};