import { addDays, differenceInDays, differenceInHours, differenceInMinutes, endOfDay, format, formatISO, getMonth, isSameDay, isWithinInterval, startOfDay, subDays } from 'date-fns';
export const isoDate = date => date ? formatISO(new Date(date), {
  representation: 'date'
}) : '';
export const shortDateFormat = (stringDate, yearDigits = 4) => {
  if (stringDate) {
    const dateFormat = yearDigits === 2 ? 'dd.MM.yy' : 'dd.MM.yyyy';
    const date = new Date(stringDate);
    return format(date, dateFormat);
  }
  return '';
};
export const dateAndTimeFormat = (stringDate, yearDigits = 4) => {
  if (stringDate) {
    const dayFormat = yearDigits === 2 ? 'dd.MM.yy' : 'dd.MM.yyyy';
    const date = new Date(stringDate);
    return format(date, `${dayFormat}, HH:mm`);
  }
  return '';
};
export const shortDateRangeFormat = (t, from, to, showTime = false, yearDigits = 4) => {
  const formatter = showTime ? dateAndTimeFormat : shortDateFormat;
  if (from && to) {
    return `${formatter(from, yearDigits)} - ${formatter(to, yearDigits)}`;
  }
  if (from) {
    return `${t('date.range.from')} ${formatter(from, yearDigits)}`;
  }
  if (to) {
    return `${t('date.range.to')} ${formatter(to, yearDigits)}`;
  }
  return '';
};
export const dateOrWeekdayFormat = (paramDate, t, thresholdFutureWeekday = 3) => {
  const date = new Date(paramDate);
  const dateToday = new Date();
  const weekdays = [t('date.sunday'), t('date.monday'), t('date.tuesday'), t('date.wednesday'), t('date.thursday'), t('date.friday'), t('date.saturday')];
  const today = t('global.today');
  const yesterday = t('global.yesterday');
  if (isSameDay(dateToday, date)) {
    return today;
  }
  if (isSameDay(date, subDays(dateToday, 1))) {
    return yesterday;
  }
  if (isWithinInterval(date, {
    start: startOfDay(dateToday),
    end: endOfDay(addDays(dateToday, thresholdFutureWeekday))
  })) {
    return weekdays[date.getDay()];
  }
  return shortDateFormat(date.toISOString());
};
export const shortDateFullMonthFormat = (paramDate, t) => {
  const months = [t('date.january'), t('date.february'), t('date.march'), t('date.april'), t('date.may'), t('date.june'), t('date.july'), t('date.august'), t('date.september'), t('date.october'), t('date.november'), t('date.december')];
  return `${format(paramDate, 'dd.')} ${months[getMonth(paramDate)]} ${format(paramDate, 'yyyy')}`;
};
export const formatTime = (date, t) => `${shortTimeFormat(date)} ${t('global.oClock')}`;
export const shortTimeFormat = paramDate => {
  const date = new Date(paramDate);
  return format(date, 'HH:mm');
};
export const parseToTime = timeString => {
  const [hourString, minuteString] = timeString.split(':');
  return {
    minutes: parseInt(minuteString),
    hours: parseInt(hourString)
  };
};
export const longTimeFormat = stringDate => {
  const date = new Date(stringDate);
  return format(date, 'HH:mm:ss');
};
export const monthYearFormat = date => format(new Date(date), 'MMM. yyyy');
export const yearFormat = date => format(new Date(date), 'yyyy');
export const distanceExpiringSoonFormat = (paramDate, t) => {
  const now = new Date();
  const date = new Date(paramDate);
  const diffInHours = differenceInHours(date, now);
  const diffInDays = differenceInDays(date, now);
  if (diffInDays > 0) {
    return t('global.dayLeft', {
      count: diffInDays
    });
  }
  if (diffInHours > 0) {
    return t('global.hourLeft', {
      count: diffInHours
    });
  }
  const diffInMinutes = differenceInMinutes(date, now);
  return t('global.minuteLeft', {
    count: diffInMinutes
  });
};