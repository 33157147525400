import { playLocalStorage } from './play-local-storage';
const storageKey = 'playSRG-subtitle';
const defaultValue = {
  showSubtitle: false
};
const mapToSubtitleState = ({
  showSubtitle
}) => showSubtitle;
const get = () => {
  const {
    getItem
  } = playLocalStorage(storageKey, defaultValue);
  return mapToSubtitleState(getItem());
};
const set = showSubtitle => {
  const {
    setItem
  } = playLocalStorage(storageKey, defaultValue);
  return mapToSubtitleState(setItem({
    showSubtitle
  }));
};
const reset = () => {
  const {
    setItem
  } = playLocalStorage(storageKey, defaultValue);
  return mapToSubtitleState(setItem(defaultValue));
};
export const subtitleStorage = {
  get,
  set,
  reset
};