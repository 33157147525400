import { playLocalStorage } from './play-local-storage';
import { randomInRange } from '../../../play-core/utils/random';
const storageKey = 'playSRG-userGroup';
const defaultValue = {
  groupNumber: randomInRange(1, 100)
};
const get = () => {
  const {
    getItem
  } = playLocalStorage(storageKey, defaultValue);
  return getItem();
};
export const userGroupStorage = {
  get
};