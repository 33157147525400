export const randomInRange = (from, to) => Math.floor(Math.random() * Math.abs(to - from)) + Math.min(to, from);
export const randomFromArray = (array = []) => {
  if (array.length > 0) {
    return array[randomInRange(0, array.length)];
  }
};
export const shuffleArray = (array = []) => {
  const newArray = [...array];
  for (let i = newArray.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }
  return newArray;
};
export const randomFromArrayIfLarger = (array, count) => {
  if (!array) return [];
  if (array.length <= count) {
    return array;
  }
  return shuffleArray(array).slice(0, count);
};