import { playLocalStorage } from './play-local-storage';
const storageKey = 'playSRG-favoriteTopics';
const defaultValue = [];
const get = () => {
  const {
    getItem
  } = playLocalStorage(storageKey, defaultValue);
  return getItem();
};
const set = favorites => {
  const {
    setItem
  } = playLocalStorage(storageKey, defaultValue);
  return setItem(favorites);
};
const reset = () => {
  const {
    setItem
  } = playLocalStorage(storageKey, defaultValue);
  return setItem(defaultValue);
};
export const favoriteTopicsStorage = {
  get,
  set,
  reset
};