import { isBrowser, isServer } from './ssr-helper';
export const localStorage = (key, defaultValue) => {
  const isValid = item => {
    if (item === null) {
      return false;
    }
    if (
    // scenario where array was changed to non-array or vice versa
    Array.isArray(item) && !Array.isArray(defaultValue) || !Array.isArray(item) && Array.isArray(defaultValue)) {
      return false;
    }
    if (!Array.isArray(item)) {
      return Object.entries(defaultValue).every(([k]) => Object.prototype.hasOwnProperty.call(item, k));
    }
    if (!Array.isArray(defaultValue)) {
      return false;
    }
    return true;
  };
  const setItem = value => {
    if (isServer) {
      return defaultValue;
    }
    try {
      window.localStorage.setItem(key, JSON.stringify(value));
      return value;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(`Failed to write local storage value: ${value} for key: ${key}`, error);
    }
    return defaultValue;
  };
  const getItem = () => {
    if (isServer) {
      return defaultValue;
    }
    try {
      const item = window.localStorage.getItem(key);
      const storedValue = item && JSON.parse(item);
      if (isValid(storedValue)) {
        return storedValue;
      }
      return setItem(defaultValue);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(`Failed to read local storage value for key: ${key}`, error);
      return defaultValue;
    }
  };
  const removeItem = () => {
    if (isBrowser) {
      try {
        window.localStorage.removeItem(key);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn(`Failed to delete local storage key: ${key}`, error);
        return defaultValue;
      }
    }
  };
  return {
    getItem,
    setItem,
    removeItem
  };
};