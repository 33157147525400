import { Link } from "@remix-run/react";
import { css, styled } from 'styled-components';
import { Colors } from '../../../../play-core/styles/Colors';
import { Fonts } from '../../../../play-core/styles/Fonts';
import { Viewports } from '../../../../play-core/styles/Viewports';
import { zIndex } from '../../../../play-core/styles/z-index';
export const Layout = styled.div.withConfig({
  displayName: "Snackbarstyles__Layout",
  componentId: "sc-bgwmqa-0"
})(["pointer-events:none;margin:8px;position:fixed;right:0;bottom:0;left:0;opacity:0;transform:translateY(40px);display:flex;justify-content:center;z-index:", ";opacity:", ";transform:", ";transition:transform 200ms ease-in,opacity 200ms ease-in;", "{margin-bottom:36px;}"], zIndex.snackbar, ({
  show
}) => show && 1, ({
  show
}) => show && 'translateY(0)', Viewports.for('mediumUp'));
export const Container = styled.div.withConfig({
  displayName: "Snackbarstyles__Container",
  componentId: "sc-bgwmqa-1"
})(["pointer-events:all;display:flex;flex-wrap:wrap;align-items:center;justify-content:flex-start;min-width:344px;max-width:672px;background-color:", ";border-radius:4px;box-shadow:0 6px 4px 0 rgba(0,0,0,0.5);padding:12px 16px;", "{min-width:100%;}"], Colors.WHITE, Viewports.for('small'));
export const Message = styled.div.withConfig({
  displayName: "Snackbarstyles__Message",
  componentId: "sc-bgwmqa-2"
})(["display:block;flex-grow:1;padding-top:6px;padding-bottom:6px;color:", ";", ""], Colors.GREY_1a1a1a, Fonts.regular(14));
export const SnackbarActions = styled.div.withConfig({
  displayName: "Snackbarstyles__SnackbarActions",
  componentId: "sc-bgwmqa-3"
})(["display:flex;padding-left:8px;padding-top:6px;padding-bottom:6px;margin-left:auto;"]);
const actionStyle = css`
  height: 16px;
  color: ${Colors.BLUE_0567FF};
  ${Fonts.medium(14)};
  white-space: nowrap;
`;
export const Action = styled.button.withConfig({
  displayName: "Snackbarstyles__Action",
  componentId: "sc-bgwmqa-4"
})(["", ""], actionStyle);
export const LinkAction = styled(Link).withConfig({
  displayName: "Snackbarstyles__LinkAction",
  componentId: "sc-bgwmqa-5"
})(["", ";padding:0;background-color:transparent;"], actionStyle);