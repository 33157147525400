import { ilHosts } from './il-hosts';
import { defaultCoreConfig } from '../../../play-core/config/CoreConfig';
import { defaultMetaConfig } from '../components/Meta/Meta.config';
export const defaultPlayConfig = {
  ...defaultCoreConfig,
  businessUnit: 'srf',
  environment: 'production',
  serverEnvironment: 'production',
  isRequestedViaCDN: false,
  baseUrl: '/srf/play',
  hostName: 'www.srf.ch',
  ilHost: ilHosts.production,
  language: 'de',
  isAbroad: false,
  meta: defaultMetaConfig,
  privacyBanner: {
    rulesetId: 'v6OFae9a5-aJ32',
    isEnabled: false
  }
};