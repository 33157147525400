/* eslint-disable no-console */
import { debugLoggingStorage } from '../../storage/debugLoggingStorage';
const group = title => {
  if (debugLoggingStorage.get()) {
    console.group(title);
  }
};
const groupEnd = () => {
  if (debugLoggingStorage.get()) {
    console.groupEnd();
  }
};
const log = (...params) => {
  if (debugLoggingStorage.get()) {
    console.log(...params);
  }
};
const error = (...params) => {
  if (debugLoggingStorage.get()) {
    console.error(...params);
  }
};
export const logger = {
  log,
  error,
  group,
  groupEnd
};