import produce from 'immer';
import { createDateQuickFilters } from './date-filter-helper';
export const initialState = {
  query: {
    duration: undefined,
    includeAggregations: false,
    mediaProperties: undefined,
    pageSize: 20,
    searchTerm: ''
  },
  showAll: false,
  dates: [],
  markedDates: {},
  dateQuickFilters: [],
  mediaLoading: false
};
export const reducer = (state, action) => produce(state, draft => {
  switch (action.type) {
    case 'set-query':
      {
        draft.query = action.query;
        return draft;
      }
    case 'set-media-loading':
      {
        draft.mediaLoading = action.isLoading;
        return draft;
      }
    case 'search-media-success':
      {
        draft.mediaResult = action.result;
        draft.dates = draft.mediaResult && draft.mediaResult.aggregations && draft.mediaResult.aggregations.dates || [];
        draft.dateQuickFilters = createDateQuickFilters(draft.dates, {
          from: draft.query.from,
          to: draft.query.to
        });
        draft.markedDates = {};
        draft.dates.forEach(item => {
          draft.markedDates[item.date] = {
            tooltip: {
              key: 'search.resultCount',
              options: {
                count: item.count
              }
            }
          };
        });
        draft.oldestResultDate = draft.dates.length > 0 ? draft.dates[draft.dates.length - 1].date : undefined;
        return draft;
      }
    case 'show-all':
      {
        draft.showAll = true;
        return draft;
      }
    case 'include-aggregations':
      {
        draft.query.includeAggregations = true;
        return draft;
      }
    case 'set-topic-filter':
      {
        draft.query.topics = action.topics;
        return draft;
      }
    case 'remove-topic-filter':
      {
        draft.query.topics = draft.query.topics?.filter(topic => topic !== action.topic);
        return draft;
      }
    case 'set-show-filter':
      {
        draft.query.shows = action.shows;
        return draft;
      }
    case 'remove-show-filter':
      {
        draft.query.shows = draft.query.shows?.filter(show => show !== action.show);
        return draft;
      }
    case 'set-date-filter':
      {
        draft.query.from = action.dateRange.from || '';
        draft.query.to = action.dateRange.to || '';
        return draft;
      }
    case 'remove-date-filter':
      {
        draft.query.from = '';
        draft.query.to = '';
        return draft;
      }
    case 'set-media-property-filters':
      {
        draft.query.mediaType = action.filters.mediaType;
        draft.query.duration = action.filters.duration;
        draft.query.mediaProperties = action.filters.mediaProperties;
        return draft;
      }
    case 'set-order':
      {
        draft.query.order = action.order === 'relevant' ? undefined : action.order;
        return draft;
      }
    case 'remove-media-type-filter':
      {
        draft.query.mediaType = undefined;
        return draft;
      }
    case 'remove-property-filter':
      {
        if (draft.query.mediaProperties) {
          draft.query.mediaProperties = draft.query.mediaProperties.filter(property => property !== action.property);
        } else {
          draft.query.mediaProperties = [];
        }
        return draft;
      }
    case 'remove-duration-filter':
      {
        draft.query.duration = undefined;
        return draft;
      }
    case 'remove-all-filters':
      {
        draft.query.shows = [];
        draft.query.topics = [];
        draft.query.mediaType = undefined;
        draft.query.mediaProperties = [];
        draft.query.duration = undefined;
        draft.query.from = '';
        draft.query.to = '';
        return draft;
      }
    default:
      return draft;
  }
});